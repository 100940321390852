export default class HistoryStorage {
    static isRedirectToConstructor = false;
    static isRedirectToMyJobs = false;
    static historyArray = [];

    static push(location) {
        if (this.historyArray.length == 0 || this.historyArray[this.historyArray.length - 1] != location) {
            this.historyArray.push(location);
        }
    }

    static findLast(path) {
        for (let i = this.historyArray.length - 1; i >= 0; i--) {
            if (this.historyArray[i].indexOf(path) != -1) {
                return this.historyArray[i];
            }
        }
        return null;
    }
}