import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    currency: "UAH",
    isNotSIMeasure: false,
    loading: false,
    success: false,
    failed: false,
    loadingData: {
        catalogProductDetails: false,
        elementsForProduct: false,
        paintForProduct: false,
    },
    home: {
        individualProjects: [],
        goods: [],
        elements: [],
        instructions: [],
        productsTypes: [],
        elementsCategories: [],
        executorRegions: [],
        executorCountries: [],
    },
    elementsAndMetals: null,
    texturedMetalList: [],
    threeDTimeError: false,
    projects: [],
    productTypes: [],
    paints: null,
    projectDetails: null,
    catalogProducts: [],
    isForMyProject: false,
    catalogDIYImage: null,
    catalogTopImage: null,
    catalogProductDetails: null,
    elementsForProduct: null,
    goodsForProduct: null,
    paintForProduct: null,
    currencuFactor: 1.0,
    executor: {
        user: null,
        products: [],
        metaTitle: null,
        metaDescription: null
    }
});

export const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_CURRENCY:
            return state.merge({
                currency: action.payload
            });
        case types.SET_ISNOTSIMEASURE:
            return state.merge({
                isNotSIMeasure: action.payload
            });

        case types.SET_PAINT_TO_PRODUCT_REQUEST: {
            return state.merge({
                loadingData: {
                    ...state.loadingData,
                    paintForProduct: true
                }
            });
        }
        case types.SET_PAINT_TO_PRODUCT_SUCCESS: {
            return state.merge({
                loadingData: {
                    ...state.loadingData,
                    paintForProduct: false
                },
                success: true,
                paintForProduct:action.payload
            })
        }

        case types.GET_PRINTS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                paints: action.payload
            });
        }

        case types.GET_LIST_ELEMENTS_AND_METALS_FOR_PRODUCT_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                elementsAndMetals: action.payload
            });
        }

        case types.THREED_TIME_OUT: {
            return state.merge({
                threeDTimeError: true,
            });
        }
        case types.THREED_TIME_OUT_FLUSH: {
            return state.merge({
                threeDTimeError: false,
            });
        }
        //#region GET_CLOSEDPROJECTS
        case types.GET_CLOSEDPROJECTS_REQUEST: {
            return state.merge({
                failed: false,
                loading: true,
            });
        }
        case types.GET_CLOSEDPROJECTS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                projects: action.payload
            });
        }
        case types.GET_CLOSEDPROJECTS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }        
        //#endregion

        //#region GET_PROJECTDETAILS
        case types.GET_PROJECTDETAILS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_PROJECTDETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                projectDetails: action.payload
            });
        }
        case types.GET_PROJECTDETAILS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_HOMEDATA
        case types.GET_HOMEDATA_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_HOMEDATA_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                home: {
                    ...state.home,
                    individualProjects: action.payload.individualProjects,
                    goods: action.payload.goods,
                    elements: action.payload.elements,
                    instructions: action.payload.instructions,
                    productsTypes: action.payload.productsTypes,
                    elementsCategories: action.payload.elementsCategories,
                    executorCountries: action.payload.executorCountries
                }
            });
        }
        case types.GET_HOMEDATA_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_CLOSEDPRODUCTS
        case types.GET_CLOSEDPRODUCTS_REQUEST: {
            return state.merge({
                loading: true,
                isForMyProject: false
            });
        }
        case types.GET_CLOSEDPRODUCTS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                catalogProducts: [...state.catalogProducts, ...action.payload.products],
                isForMyProject: action.payload.isForMyProject,
                catalogDIYImage: action.payload.catalogDIYImage,
                catalogTopImage: action.payload.catalogTopImage,
            });
        }
        case types.GET_CLOSEDPRODUCTS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        case types.CLEAR_CLOSEDPROJECTS: {
            return state.merge({
                catalogProducts: []
            });
        }
        //#endregion

        //#region GET_PRODUCTTDETAILS
        case types.GET_PRODUCTTDETAILS_REQUEST: {
            return state.merge({
                loading: true,
                catalogProductDetails: null,
                loadingData: {
                    ...state.loadingData,
                    catalogProductDetails: true
                }
            });
        }
        case types.GET_PRODUCTTDETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                catalogProductDetails: action.payload,
                loadingData: {
                    ...state.loadingData,
                    catalogProductDetails: false
                },
                paintForProduct: null
            });
        }
        case types.GET_PRODUCTTDETAILS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
                loadingData: {
                    ...state.loadingData,
                    catalogProductDetails: false
                },
            });
        }
        //#endregion

        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false
            });
        }
        case types.SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        case types.GET_ELEMENTS_FOR_PRODUCT_REQUEST: {
            return state.merge({
                loadingData: {
                    ...state.loadingData,
                    elementsForProduct: true
                },
                elementsForProduct: []
            });
        }
        case types.GET_ELEMENTS_FOR_PRODUCT_SUCCESS: {
            return state.merge({
                loadingData: {
                    ...state.loadingData,
                    elementsForProduct: false
                },
                success: true,
                elementsForProduct: action.payload.elements,
                goodsForProduct: action.payload.goods,
                currencuFactor: action.payload.currencuFactor
            });
        }

        case types.GET_CURRENCY_INFO_SUCCESS: {
            return state.merge({
                loading: false,
                currencuFactor: action.payload.factor
            });
        }

        case types.GET_PRODUCT_TYPES_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                productTypes: action.payload
            });
        }

        case types.REQUEST_METALS_LIST: {
            return state.merge({
                loading: true,
            })
        }
        case types.FAILURE_METALS_LIST: {
            return state.merge({
                loading: false,
            })
        }

        case types.GET_TEXTURED_METALS_LIST_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                texturedMetalList: action.payload
            })
        }

        case types.GET_EXECUTOR_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                executor: {
                    user: action.payload.user,
                    products: action.payload.products,
                    metaTitle: action.payload.metaTitle,
                    metaDescription: action.payload.metaDescription,
                }
            });
        }

        case types.GET_CATALOG_DIY_IMAGE_SUCCESS: {
            return state.merge({
                catalogDIYImage: action.payload.catalogDIYImage,
            });
        }

        default: {
            return state;
        }
    }
}