import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    uploadedImages: [],
    myproducts: []
});

export const editUserProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }

        case types.UPLOAD_USER_PRODUCT_IMAGE_SUCCESS: {
            return state.merge({
                loading: false,
                uploadedImages: [...state.uploadedImages, action.payload]
            });
        }

        case types.SAVE_USER_PRODUCT_SUCCESS: {
            return state.merge({
                loading: false,
                uploadedImages: []
            });
        }

        case types.GET_MY_USER_PRODUCTS_SUCCESS: {
            return state.merge({
                loading: false,
                myproducts: action.payload
            });
        }

        case types.DELETE_USER_PRODUCT_SUCCESS: {
            return state.merge({
                loading: false,
                myproducts: state.myproducts.filter(p => p.id != action.payload)
            });
        }

        case types.CHANGE_PRICE_PRODUCT_SUCCESS: {
            return state.merge({
                loading: false                
            });
        }

        default: {
            return state;
        }
    }
}