import React from 'react';
import './style.scss';

export default class FullPageLoaderNoText extends React.Component {
    render() {
        return (
            <div className="fullPageLoader spinner-background">
                <div className="spinner-grow text-success" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}