import store from '../../store/configureStore';

export class Types {
    static SUCCESS = 'toastr/SUCCESS';
    static WARNING = 'toastr/WARNING';
    static ERROR = 'toastr/ERROR';
    static HIDE = 'toastr/HIDE';
}

export class toastr {
    static success(title, message) {
        store.dispatch({
            type: Types.SUCCESS,
            title: title,
            message: message
        });
    }
    static warning(title, message) {
        store.dispatch({
            type: Types.WARNING,
            title: title,
            message: message
        });
    }
    static error(title, message) {
        store.dispatch({
            type: Types.ERROR,
            title: title,
            message: message
        });
    }
    static hide() {
        store.dispatch({
            type: Types.HIDE,
        });
    }
}
