import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    get: {
        regions:[],
        loading: false,
        failed:false,
        success: false,
        countries: [],
        searchCities: []
    },
});


export const regionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REGIONS_REQUEST: { 
            return initialState.merge({
                get:{
                    ...initialState.get,
                }
            });
        }
        case types.GET_REGIONS_SUCCESS: {
            return state.merge({
                get:{
                    ...state.get,
                    success:true,
                    regions:action.payload.regions
                }
            });
        }
        case types.GET_REGIONS_FAILURE: {
            return state.merge({
                get:{
                    ...initialState.get,
                    failed:true
                }
            });
        }


        case types.GET_REQUEST: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: true
                }
            });
        }
        case types.GET_FAILURE: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: false,
                    failed: true
                }
            });
        }
        case types.GET_COUNTRIES_SUCCESS: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: false,
                    countries: action.payload
                }
            });
        }
        case types.GET_CITIES_SUCCESS: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: false,
                    searchCities: action.payload
                }
            });
        }
        case types.CLEAR_SEARCH_CITIES: {
            return state.merge({
                get: {
                    ...state.get,                    
                    searchCities: []
                }
            });
        }
       

        default: {
            return state;
        }
    }
}