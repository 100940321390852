import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr, Types } from "./toastr";
import './ToastrView.scss';


class ToastrView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.show) {
                setTimeout(() => {
                    toastr.hide();
                }, 5000);
            }
        }
    }

    colorFromType = (messageType) => {
        switch (messageType) {
            case Types.SUCCESS:
                return "#60bb71";
            case Types.WARNING:
                return "#f7a336";
            case Types.ERROR:
                return "#db6a64";
        }
        return "#60bb71";
    }

    render() {
        const { show, title, message, messageType } = this.props;
        return (
            <div
                id="ToastrView"
                className={show ? "visible" : "hidden"}
                style={{ backgroundColor: this.colorFromType(messageType) }}
            >
                {title && <div className="title">{title}</div>}
                {message && <div className="message">{message}</div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.toastr
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastrView);