export const GET_REGIONS_REQUEST = 'regions/GET_REGIONS_REQUEST'
export const GET_REGIONS_SUCCESS = 'regions/GET_REGIONS_SUCCESS'
export const GET_REGIONS_FAILURE = 'regions/GET_REGIONS_FAILURE'

export const GET_REQUEST = 'regions/GET_COUNTRIES_REQUEST';
export const GET_FAILURE = 'regions/GET_COUNTRIES_FAILURE';

export const GET_COUNTRIES_SUCCESS = 'regions/GET_COUNTRIES_SUCCESS';
export const GET_CITIES_SUCCESS = 'regions/GET_CITIES_SUCCESS';
export const CLEAR_SEARCH_CITIES = 'regions/CLEAR_SEARCH_CITIES';
