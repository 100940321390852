export default class MathHelper {
    static metersToMm(m) {
        return Math.round(m * 1000);
    }

    static mmToMeters(mm) {
        return mm / 1000.0;
    }

    static radToDegree(rad) {
        return Math.round(rad * (180 / Math.PI));
    }

    static degreeToRad(degree) {
        return degree * (Math.PI / 180.0);
    }

    static mmToInch(mm) {
        return mm / 25.4;
    }

    static inchToMm(inch) {
        return Math.round(inch * 25.4);
    }

    static mmToUserVal(mm, isNotSIMeasure) {
        if (isNotSIMeasure) {
            return this.mmToInch(mm);
        }
        return mm;
    }

    static mmFromUserVal(val, isNotSIMeasure) {
        if (isNotSIMeasure) {
            return this.inchToMm(val);
        }
        return val;
    }

    static uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
}