export const GET_ELEMENTS_REQUEST = 'elements/GET_ELEMENTS_REQUEST';
export const GET_ELEMENTS_SUCCESS = 'elements/GET_ELEMENTS_SUCCESS';
export const GET_ELEMENTS_FAILURE = 'elements/GET_ELEMENTS_FAILURE';

export const GET_FILTER_REQUEST = 'elements/GET_FILTER_REQUEST';
export const GET_FILTER_SUCCESS = 'elements/GET_FILTER_SUCCESS';
export const GET_FILTER_FAILURE = 'elements/GET_FILTER_FAILURE';

export const SET_ELEMENTS_ZERO = 'elements/SET_ELEMENTS_ZERO';
export const GET_LINK_SUCCESS = 'elements/GET_LINK_SUCCESS';

export const GET_FILTER_V2_REQUEST = 'elements/GET_FILTER_V2_REQUEST';
export const GET_FILTER_V2_SUCCESS = 'elements/GET_FILTER_V2_SUCCESS';
export const GET_FILTER_V2_FAILURE = 'elements/GET_FILTER_V2_FAILURE';

export const GET_ELEMENT_DETAILS_REQUEST = 'elements/GET_ELEMENT_DETAILS_REQUEST';
export const GET_ELEMENT_DETAILS_SUCCESS = 'elements/GET_ELEMENT_DETAILS_SUCCESS';
export const GET_ELEMENT_DETAILS_FAILURE = 'elements/GET_ELEMENT_DETAILS_FAILURE';

export const GET_ELEMENT_EXAMPLES_REQUEST = 'elements/GET_ELEMENT_EXAMPLES_REQUEST';
export const GET_ELEMENT_EXAMPLES_SUCCESS = 'elements/GET_ELEMENT_EXAMPLES_SUCCESS';
export const GET_ELEMENT_EXAMPLES_FAILURE = 'elements/GET_ELEMENT_EXAMPLES_FAILURE';

export const GET_HOME_INSTRUCTIONS_SUCCESS = 'elements/GET_HOME_INSTRUCTIONS_SUCCESS';
export const GET_HOME_INSTRUCTIONS_FAILURE = 'elements/GET_HOME_INSTRUCTIONS_FAILURE';

export const GET_ELEMENT_MATCHES_REQUEST = 'elements/GET_ELEMENT_MATCHES_REQUEST';
export const GET_ELEMENT_MATCHES_SUCCESS = 'elements/GET_ELEMENT_MATCHES_SUCCESS';
export const GET_ELEMENT_MATCHES_FAILURE = 'elements/GET_ELEMENT_MATCHES_FAILURE';

export const POST_ELEMENT_REVIEW_REQUEST = 'elements/POST_ELEMENT_REVIEW_REQUEST';
export const POST_ELEMENT_REVIEW_SUCCESS = 'elements/POST_ELEMENT_REVIEW_SUCCESS';
export const POST_ELEMENT_REVIEW_FAILURE = 'elements/POST_ELEMENT_REVIEW_FAILURE';

export const GET_ELEMENT_REVIEWS_REQUEST = 'elements/GET_ELEMENT_REVIEWS_REQUEST';
export const GET_ELEMENT_REVIEWS_SUCCESS = 'elements/GET_ELEMENT_REVIEWS_SUCCESS';
export const GET_ELEMENT_REVIEWS_FAILURE = 'elements/GET_ELEMENT_REVIEWS_FAILURE';