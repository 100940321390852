import Immutable from 'seamless-immutable';

import { Types } from './toastr';

const initialState = Immutable({
    show: false,
    title: '',
    message: '',
    messageType: 1
});

export const toastrReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.SUCCESS:
        case Types.WARNING:
        case Types.ERROR:
            {
                return state.merge({
                    show: true,
                    title: action.title,
                    message: action.message,
                    messageType: action.type
                });
            }
        case Types.HIDE:
            {
                return state.merge({
                    show: false,
                });
            }

        default: {
            return state;
        }
    }
}