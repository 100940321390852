export class LanguageStorage {
    static currentLang = null;
}

export function localizePath(path) {
    let pathNew = path;
    const pathParts = window.location.pathname.split('/');
    if (!LanguageStorage.currentLang && pathParts.length >= 2) {
        if (pathParts[1] == 'en' || pathParts[1] == 'ru') {
            LanguageStorage.currentLang = pathParts[1];
        }
    }
    if (LanguageStorage.currentLang) {
        pathNew = "/" + LanguageStorage.currentLang + pathNew;
    }
    return pathNew;
}

export function localeFromPath(path) {
    const pathParts = path.split('/');
    let lang = 'ua';
    if (pathParts.length >= 2) {
        if (pathParts[1] == 'en' || pathParts[1] == 'ru') {
            lang = pathParts[1];
        }
    }
    return lang;
}

export function getPathWithoutLang(path) {
    if (path.startsWith("/ru/") || path.startsWith("/en/")) {
        return path.substring(3);
    }
    return path;
}