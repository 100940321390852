import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

const rootElement = document.getElementById('root');
ReactDOM.render(
    <App />
    ,
    rootElement);

/*
 * <App />
 <Provider store={store}>
        <ConnectedRouter history={history}>
            <ReduxToastr
                className='alert-font'
                timeOut={5000}
            />
            <Router history={history}>
                <App history={history} />
                empty
            </Router>
        </ConnectedRouter>
    </Provider>
    */
