import jwt from '../../utils/jwt';
import { refreshToken } from '../../refreshToken/actions';
import { getAuthToken } from '../../utils/lsMethods';

export default function refreshTokenMiddleware(){
    return ({ dispatch, getState }) => next => action => {
        if (typeof action === 'function' && !getState().refreshToken.loading) {
            const token = jwt.decode(getAuthToken());
            const refreshThreshold = (Math.round(Date.now() / 1000));
            if (token && refreshThreshold > token.exp) {
                refreshToken(dispatch).then(() => {
                    return next(action)
                }, err=> { throw err; })
                .catch((err) => {
                    return next(action)
                });
            }
            else{
                return next(action);
            }
        }
        else{
            return next(action);
        }
        
    }
}