import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    isShowPopup: false,
    loading: false,
    failed: false,
    questions: null,
    currentProductTypeId: -1,
    productTypes: []
});

export const questionnairePTReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_POPUP: {
            return state.merge({
                isShowPopup: !state.isShowPopup
            });
        }
        case types.SET_PRODUCT_TYPE: {
            return state.merge({
                currentProductTypeId: action.payload
            });
        }
        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }

        case types.GET_QUESTIONS_SUCCESS: {
            return state.merge({
                loading: false,
                questions: action.payload
            });
        }

        case types.SEND_ANSWERS_SUCCESS: {
            return state.merge({
                loading: false
            });
        }
        case types.GET_PRODUCT_TYPES_SUCCESS: {
            return state.merge({
                loading: false,
                productTypes: action.payload
            });
        }

        default: {
            return state;
        }
    }
}