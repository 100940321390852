export const GET_MYINFO_REQUEST = 'myinfo/GET_MYINFO_REQUEST';
export const GET_MYINFO_SUCCESS = 'myinfo/GET_MYINFO_SUCCESS';
export const GET_MYINFO_FAILURE = 'myinfo/GET_MYINFO_FAILURE';

export const SAVE_MYINFO_REQUEST = 'myinfo/SAVE_MYINFO_REQUEST';
export const SAVE_MYINFO_SUCCESS = 'myinfo/SAVE_MYINFO_SUCCESS';
export const SAVE_MYINFO_FAILURE = 'myinfo/SAVE_MYINFO_FAILURE';

export const EDIT_MYINFO = "myinfo/EDIT_MYINFO";

export const SEND_EMAILCONFIRMATION_REQUEST = 'myinfo/SEND_EMAILCONFIRMATION_REQUEST';
export const SEND_EMAILCONFIRMATION_SUCCESS = 'myinfo/SEND_EMAILCONFIRMATION_SUCCESS';
export const SEND_EMAILCONFIRMATION_FAILURE = 'myinfo/SEND_EMAILCONFIRMATION_FAILURE';

export const USER_SITE_REQUEST = 'myinfo/USER_SITE_REQUEST';
export const USER_SITE_FAILURE = 'myinfo/USER_SITE_FAILURE';
export const GET_USER_SITE_SUCCESS = 'myinfo/GET_USER_SITE_SUCCESS';
export const GENERATE_USER_SITE_SUCCESS = 'myinfo/GENERATE_USER_SITE_SUCCESS';
export const SAVE_USER_SITE_SUCCESS = 'myinfo/SAVE_USER_SITE_SUCCESS';
export const SAVE_USER_SITE_FAILURE = 'myinfo/SAVE_USER_SITE_FAILURE';