export const REQUEST = 'embedding/REQUEST';
export const FAILURE = 'embedding/FAILURE';

export const REQUEST_PRODUCTS = 'embedding/REQUEST_PRODUCTS';

export const GET_PRODUCTS_FOR_PROFILE_SUCCESS = 'embedding/GET_PRODUCTS_FOR_PROFILE_SUCCESS';
export const SAVE_EXECUTOR_PRODUCT_SELECTION = 'embedding/SAVE_EXECUTOR_PRODUCT_SELECTION';

export const GENERATE_KEY = 'embedding/GENERATE_KEY';
export const GET_EXISTING_KEY = 'embedding/GET_EXISTING_KEY';
export const GET_PRICE_INFO = 'embedding/GET_PRICE_INFO';
export const GENERATE_KEY_FAILURE = 'embedding/GENERATE_KEY_FAILURE';

export const GET_PRODUCT_TYPES = 'embedding/GET_PRODUCT_TYPES';
export const GET_PRODUCTS_FOR_EMBEDDING = 'embedding/GET_PRODUCTS_FOR_EMBEDDING';
export const GET_PRODUCTS_FOR_EMBEDDING_FAILURE = 'embedding/GET_PRODUCTS_FOR_EMBEDDING_FAILURE';
export const GET_PRODUCT_DETAILS = 'embedding/GET_PRODUCT_DETAILS';
export const RESET_FAILURE = 'embedding/RESET_FAILURE';

export const GET_LOADED_IMAGE_SUCCESS = 'embedding/GET_LOADED_IMAGE_SUCCESS';
export const DELETE_IMAGE_SUCCESS = 'embedding/DELETE_IMAGE_SUCCESS';
export const CHANGE_WRAPPER_SUCCESS = 'embedding/CHANGE_WRAPPER_SUCCESS';

export const SET_PARENT_URL_SUCCESS = 'embedding/SET_PARENT_URL_SUCCESS';
export const GET_PARENT_URL_SUCCESS = 'embedding/GET_PARENT_URL_SUCCESS';

